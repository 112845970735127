import axios from "axios";
import { GetCookie, RemoveCookie } from "../provider/common";
import { getReferrer } from "../provider/referrer";

import qs from "qs";

const API_URL = process.env.PANDA_API_URL;

export async function authLogin(email, password) {
  return await axios
    .post(
      API_URL + "/api/auth/login",
      {
        email: email,
        password: password,
      },
      { headers: { Authorization: "Bearer " + (await GetCookie("token")) } }
    )
    .then(function (response) {
      //장바구니 숫자
      //견적 숫자 최신화함
      // handle success
      return response.data;
    })
    .catch(function (error) {
      // handle error
      return error.response.data;
    });
}

export async function anonymous() {
  try {
    const req = await axios.post(API_URL + "/api/auth/anonymous");
    return req.data.access_token;
  } catch {
    return undefined;
  }
}

export async function authLogoutEnglish() {
  await RemoveCookie("token");
  await RemoveCookie("isLogin");
  await RemoveCookie("cartCnt");
  //로그아웃 후 메인으로 페이지 이동 처리
  window.location.replace("/en");
}

export async function authLogout() {
  await RemoveCookie("token");
  await RemoveCookie("isLogin");
  await RemoveCookie("cartCnt");
  //로그아웃 후 메인으로 페이지 이동 처리
  window.location.replace("/");
}

export async function socialLoginPop(type) {
  const myUri = window.location.protocol + "//" + window.location.host;
  const callbackUri = myUri + "/auth/callback?type=" + type;
  let socialLoginUrl =
    API_URL + "/api/user/" + type + "_login?redirect_url=" + callbackUri;
  if (type === "kakao") {
    //카카오는 기본 인증 자체적으로 처리해야되서 프론트에서 처리
    socialLoginUrl =
      process.env.KAKAO.API_BASE_ADDR +
      "/oauth/authorize?client_id=" +
      process.env.KAKAO.API_KEY +
      "&redirect_uri=" +
      callbackUri +
      "&response_type=code";
  }
  window.open(
    socialLoginUrl,
    "소셜로그인",
    "width=850, height=600, menubar=no, status=no, toolbar=no"
  );
}

export async function socialLogin(type, code, state) {
  return await axios
    .post(
      API_URL +
        "/api/user/social_login?type=" +
        type +
        "&code=" +
        code +
        "&state=" +
        state,
      {},
      { headers: { Authorization: "Bearer " + (await GetCookie("token")) } }
    )
    .then(function (response) {
      //장바구니 숫자
      //견적 숫자 최신화함
      // handle success
      return response.data;
    })
    .catch(function (error) {
      // handle error
      return error.response.data;
    });
}

export async function signUp(email, password, name, company_name) {
  //DB랑 칼럼명을 맞춤
  return await axios
    .post(
      API_URL + "/api/user",
      {
        mb_id: email,
        mb_password: password,
        mb_name: name,
        mb_nick: company_name,
      },
      { headers: { Authorization: "Bearer " + (await GetCookie("token")) } }
    )
    .then(function (response) {
      // handle success
      return response.data;
    })
    .catch(function (error) {
      // handle error
      alert(
        "가입처리 도중 오류가 발생했습니다. 잠시 후 다시 시도 요청드립니다."
      );
      return;
    });
}

export async function decodeToken() {
  return await axios
    .post(
      API_URL + "/api/auth/decode",
      { token: await GetCookie("token") },
      {
        headers: { Authorization: "Bearer " + (await GetCookie("token")) },
      }
    )
    .then(function (response) {
      // handle success
      return response.data;
    })
    .catch(function (error) {
      // handle error
      return error.response.data;
    });
}

// 접속자 얻는 함수
export async function getUserInfo() {
  return await axios
    .get(API_URL + "/api/user", {
      headers: { Authorization: "Bearer " + (await GetCookie("token")) },
    })
    .then(function (response) {
      // handle success
      return response.data;
    })
    .catch(function (error) {
      // handle error
      return error.response.data;
    });
}

// 비밀번호찾기 페이지에서 이메일 보내는 함수
export async function sendEmail(email) {
  const userEmail = { email: email };
  return await axios
    .post(API_URL + "/api/sendgrid?type=panda_reset_password", userEmail, {
      headers: { Authorization: "Bearer " + (await GetCookie("token")) },
    })
    .then(function (response) {
      if (response.data.status === 400) {
        alert("가입되지 않는 이메일 입니다.");
      } else if (response.data.status === 401) {
        alert("간편 로그인 회원입니다. 간편 로그인으로 로그인해주세요");
      } else {
        alert("이메일을 확인해주세요");
        window.location.href = "/";
      }
      return;
    })
    .catch(function (error) {
      console.log(error);
    });
}

// 비밀번호찾기 페이지에서 비밀번호 재설정하는 함수
export async function setNewPassword(email, key, password) {
  const data = {
    email: email,
    password: password,
    key: key,
  };
  return await axios
    .patch(API_URL + "/api/user/password", data, {
      headers: { Authorization: "Bearer " + (await GetCookie("token")) },
    })
    .then(function (response) {
      if (response.data.status === 200) {
        alert("비밀번호가 재설정되었습니다!");
        window.location.href = "/auth/login";
      } else {
        alert("비밀번호가 재설정을 실패하였습니다!");
      }
      return;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

export async function kakaoToken(code) {
  const callbackUri =
    window.location.protocol +
    "//" +
    window.location.host +
    "/auth/callback?type=kakao";
  const api_url = process.env.KAKAO.API_BASE_ADDR + "/oauth/token";

  const body = {
    client_id: process.env.KAKAO.API_KEY,
    redirect_uri: encodeURI(callbackUri),
    code: code,
    grant_type: "authorization_code",
  };

  const responseToken = await axios.post(api_url, qs.stringify(body), {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });

  if (responseToken.status === 200) {
    return responseToken.data;
  } else {
    alert("카카오 로그인처리중 에러가 발생했습니다. 다시 시도해주세요!!");
  }
}

export async function isMemberCheck() {
  if ((await GetCookie("isLogin")) == 1) {
    return 1;
  } else {
    return (window.location.href = `/auth/login?before=${getReferrer()}`);
  }
}

export async function isLoginCheck() {
  const isLogin = Number(await GetCookie("isLogin"));
  if (isLogin === 1) {
    return true;
  } else {
    return false;
  }
}

export async function forceLogin(email_id) {
  return await axios
    .post(process.env.PANDA_API_URL + "/api/auth/admin", {
      mb_id: email_id,
    })
    .then((res) => {
      return res.data.access_token;
    });
}
